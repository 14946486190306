export const getFileUrl = file => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise(resolve => { reader.onload = () => resolve(reader.result) })
}

export const getFileData = file => {
  const reader = new FileReader()
  reader.readAsText(file)
  return new Promise(resolve => { reader.onload = () => resolve(reader.result) })
}
