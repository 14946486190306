<template>
  <v-container class="px-0 pb-6">
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-row class="mt-9 mb-9">
          <v-col cols="12">
            <h2 class="mb-6" v-text="$t('club.clubEnrollmentForm.confirmation.title')" />
            <div class="summary">
              <div class="mb-9 pa-4 pa-sm-8 rounded-lg summary__resume">
                <div class="d-flex justify-space-between mr-6">
                  <h2 v-text="$t('club.clubEnrollmentForm.intro.clubData')" />
                  <v-btn icon small color="primary" @click="$router.push({ name: 'club-enrollment-form-step-1' })">
                    <v-icon class="d-block mr-1" v-text="'edit_outline'" />
                    <span v-text="$t('common.edit')" />
                  </v-btn>
                </div>
                <div class="my-8 rounded-lg summary__resume__divider" />
                <div class="d-flex flex-column flex-sm-row mt-9">
                  <v-avatar size="80">
                    <img class="mr-sm-4" :src="logo">
                  </v-avatar>
                  <div>
                    <h2 v-text="globalForm.name" />
                    <p v-if="!disabledFieldIds.includes('legalName')"
                       class="mb-0 text-subtitle-2 font-weight-regular grey50--text" v-text="globalForm.legalName"
                    />
                    <p v-if="!disabledFieldIds.includes('legalEntityId')"
                       class="mb-0 text-subtitle-2 font-weight-bold grey50--text" v-text="`${$t('club.editClub.cif/nif')}: ${globalForm.legalEntityId}`"
                    />
                    <p v-if="!disabledFieldIds.includes('isFederated')"
                       class="mb-0 text-subtitle-2 font-weight-regular grey50--text"
                       v-text="`${$t('club.clubEnrollmentForm.confirmation.federated')}:
                       ${globalForm.isFederated ? globalForm.federationPlace : $t('common.no') }`"
                    />
                    <a v-if="!disabledFieldIds.includes('website') && globalForm.website"
                       :href="globalForm.website" target="_blank"
                    >
                      {{ globalForm.website }}
                    </a>
                  </div>
                </div>
                <div v-if="!disabledFieldIds.includes('kitsByIndex')">
                  <div v-for="(index) in [0, 1]" class="mt-9">
                    <h3 class="mb-5" v-text="`${index + 1}.º ${$t('club.editClub.equipement')}`" />
                    <div class="d-flex justify-space-between justify-sm-start summary__resume__kits">
                      <p v-text="`${$t('club.editClub.tShirtPrint')}:`" /><p>{{ globalForm.kitsByIndex[index].jersey.type | getTextJerseyStyle }}</p>
                    </div>
                    <div class="d-flex justify-space-between justify-sm-start summary__resume__kits">
                      <p v-text="$t('club.editClub.jerseyColor')" />
                      <div class="d-flex">
                        <color-field :value="globalForm.kitsByIndex[index].jersey.color1" disabled class="mr-4" />
                        <color-field v-if="globalForm.kitsByIndex[index].jersey.type !== 'solid'" :value="globalForm.kitsByIndex[index].jersey.color2" disabled />
                      </div>
                    </div>
                    <div class="d-flex justify-space-between justify-sm-start summary__resume__kits">
                      <p v-text="$t('club.editClub.pantsColor')" />
                      <div class="d-flex">
                        <color-field :value="globalForm.kitsByIndex[index].pants.color1" disabled />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!disabledFieldIds.includes('address')" class="mt-9">
                  <h3 class="mb-5" v-text="$t('common.address')" />
                  <div class="d-flex" style="max-width: 341px">
                    <v-icon class="mr-2" color="primary" v-text="'location_on'" />
                    <span v-text="`${globalForm.address.line1} ${globalForm.address.line2}, ${globalForm.address.zip}, ${globalForm.address.city}
                    (${globalForm.address.region}) - ${getTextCountry(globalForm.address.country)}`"
                    />
                  </div>
                </div>
                <div v-if="!disabledFieldIds.includes('requiresAccomodation')" class="mt-9">
                  <h3 class="mb-5" v-text="$t('club.accomodation.title')" />
                  <ul>
                    <li v-text="globalForm.requiresAccomodation ?
                      $t('club.clubEnrollmentForm.confirmation.activeAccomodationOffers') :
                      $t('club.clubEnrollmentForm.confirmation.requiresAccomodationFalse')"
                    />
                  </ul>
                </div>
              </div>
              <div class="mb-9 pa-4 pa-sm-8 rounded-lg summary__resume">
                <div class="d-flex justify-space-between mr-6">
                  <h2 v-text="$t('club.clubEnrollmentForm.intro.addTeams')" />
                  <v-btn icon small color="primary" @click="$router.push({ name: 'club-enrollment-form-step-2' })">
                    <v-icon class="d-block mr-1" v-text="'edit_outline'" />
                    <span>{{ $t('common.edit') }}</span>
                  </v-btn>
                </div>
                <div class="my-8 rounded-lg summary__resume__divider" />
                <h3 class="mt-9 mb-5" v-text="$t('common.teams')" />
                <ul v-for="(cohort) in globalForm.cohorts" :key="cohort.listId">
                  <li class="mb-6" v-text="`${cohortInfo(cohort.id)} - ${cohort.teamCount } ${$t('common.teams')}`" />
                </ul>
              </div>
              <div class="mb-9 pa-4 pa-sm-8 rounded-lg summary__resume">
                <div class="d-flex justify-space-between mr-6">
                  <h2 v-text="$t('club.clubEnrollmentForm.staffData.title')" />
                  <v-btn icon small color="primary" @click="$router.push({ name: 'club-enrollment-form-step-3' })">
                    <v-icon class="d-block mr-1" v-text="'edit_outline'" />
                    <span>{{ $t('common.edit') }}</span>
                  </v-btn>
                </div>
                <div class="my-8 rounded-lg summary__resume__divider" />
                <div v-for="(staffer, index) in globalForm.staff" :key="staffer.email" class="mt-9">
                  <h3 class="mb-5" v-text="`${$t('club.staff')} ${index + 1}`" />
                  <div class="d-flex mb-1">
                    <v-icon class="mr-2" color="primary" v-text="'person_outline'" />
                    <div class="d-flex flex-column">
                      <p class="mb-0" v-text="`${staffer.firstName} ${staffer.lastName}`" />
                      <p class="mb-0 text-subtitle-2 font-weight-regular grey50--text" v-text="staffer.position" />
                    </div>
                  </div>
                  <div class="d-flex mb-1">
                    <v-icon class="mr-2" color="primary" v-text="'mail_outline'" />
                    <span v-text="staffer.email" />
                  </div>
                  <div class="d-flex mb-1">
                    <v-icon class="mr-2 d-block" style="width: 24px" color="primary" v-text="'phone_outline'" />
                    <span class="mr-4" v-text="`+${staffer.phone.code}`" /><strong v-text="`${staffer.phone.number}`" />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import preventReload from '@/utils/mixins/preventReload'
import { getFileUrl } from '@/utils/fileReader'
import i18nCountries from '@/utils/mixins/i18nCountries'
import clubJerseyStylesEnum from '@/enums/clubJerseyStylesEnum'

export default {
  name: 'Summary',
  components: {
    ColorField: () => import('@/components/formFields/ColorField'),
  },
  filters: {
    getTextJerseyStyle: value => clubJerseyStylesEnum.find(j => j.value === value)?.text,
  },
  mixins: [i18nCountries, preventReload],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    globalForm: { type: Object, required: true },
  },
  data: () => ({ logo: null }),
  computed: {
    ...mapGetters({ cohorts: 'competition/cohorts' }),
    ...mapGetters({ regions: 'competition/regions' }),
    ...mapGetters({ categories: 'competition/categories' }),
    ...mapGetters('clubEnrollmentForm', ['template']),
    disabledFieldIds: ({ template }) => template?.disabledFieldIds ?? [],
  },
  async created() {
    window.scrollTo(0, 0)
    this.logo = await getFileUrl(this.globalForm.logo)
  },
  methods: {
    cohortInfo(cohortId) {
      const cohort = this.cohorts.find(c => c.id === cohortId)
      const nameRegion = this.regions.find(r => r.id === cohort?.regionId)?.name
      const nameCategory = this.categories.find(c => c.id === cohort?.categoryId)?.name
      return `${nameRegion ? `${nameRegion} - ` : ''}${nameCategory}`
    },
  },
}
</script>
<style lang="scss" scoped>
.summary__resume {
  background: $grey05;
}

.summary__resume__divider {
  height: 10px;
  background: #FFFFFF;
}

.summary__resume__kits p {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex: 0 0 196px;
  }
}
</style>
